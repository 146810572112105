<template>
  <v-row
    no-gutters
    class="product-filters accent lighten-1 mx-0 mb-5 mt-0 pa-3"
    align="center"
    justify="space-between"
  >
    <template v-if="facetsFilter">
      <v-col cols="12" sm="9" md="8" class="d-flex">
        <ProductFacetFilterMobile
          v-if="isMobile"
          :facets="facets"
          :facetsFilter="facetsFilter"
          :promoFilter="promoFilter"
          :newFilter="newFilter"
          :sortFilter="sortFilter"
          :filteredProductsCount="filteredProductsCount"
          :hideFilterString="hideFilterString"
          @handlePromoFilter="handlePromoFilter"
          @handleNewFilter="handleNewFilter"
          @handleFacetFilterMobile="handleFacetFilterMobile"
          @handleSortFilter="handleSortFilter"
        />

        <ProductFacetFilterDesktop
          v-else
          :facets="facets"
          :facetsFilter="facetsFilter"
          :hideFilterString="hideFilterString"
          @handleFacetFilter="handleFacetFilter"
        />
      </v-col>
    </template>
    <v-col
      cols="4"
      sm="3"
      md="4"
      class="d-none d-sm-flex align-center justify-end"
    >
      <ProductSortFilter
        v-if="!isMobile"
        :sortFilter="sortFilter"
        @handleSortFilter="handleSortFilter"
      />
    </v-col>
  </v-row>
</template>
<style scoped lang="scss">
.product-filters {
  height: 75px;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    height: auto;
  }
}
</style>
<script>
import ProductFacetFilterMobile from "@/components/product/filters/ProductFacetFilterMobile.vue";
import ProductSortFilter from "@/components/product/filters/ProductSortFilter.vue";
import ProductFacetFilterDesktop from "@/components/product/filters/ProductFacetFilterDesktop.vue";
export default {
  name: "ProductFilters",
  components: {
    ProductFacetFilterMobile,
    ProductFacetFilterDesktop,
    ProductSortFilter
  },
  props: {
    promoFilter: { type: Boolean },
    newFilter: { type: Boolean },
    facets: { type: Array },
    facetsFilter: { type: Object },
    sortFilter: { type: Object },
    filteredProductsCount: { type: Number },
    hidePromoFilter: { type: Boolean, default: false },
    hideFilterString: { type: String, required: false },
    hideSort: { type: Boolean, default: false }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    }
  },
  methods: {
    handleSortFilter(sort) {
      this.$emit("handleSortFilter", sort);
    },
    handlePromoFilter(promo) {
      this.$emit("handlePromoFilter", promo);
    },
    handleNewFilter(onlyNew) {
      this.$emit("handleNewFilter", onlyNew);
    },
    handleFacetFilter(filters) {
      this.$emit("handleFacetFilter", filters);
    },
    handleFacetFilterMobile(selectedFacetsXS) {
      this.$emit("handleFacetFilterMobile", selectedFacetsXS);
    }
  }
};
</script>
