<template>
  <v-card
    outlined
    rounded="md"
    class="product-entity-relationship-card"
    @click.prevent="handleLink"
  >
    <v-img cover :src="proposal.img" alt="prodotto promozionale">
      <v-card-actions class="justify-center align-end h-100">
        <v-btn
          color="secondary"
          outlined
          x-large
          class="px-8 font-weight-bold"
          :to="link"
        >
          {{
            proposal.descr
              ? proposal.descr
              : $t("products.entityRelationshipBtn")
          }}
        </v-btn>
      </v-card-actions>
    </v-img>
  </v-card>
</template>
<style lang="scss">
.product-entity-relationship-card {
  .v-image {
    height: $product-card-height;
  }
}
</style>
<script>
import banner from "~/mixins/banner";

export default {
  name: "ProductProposalCard",
  mixins: [banner]
};
</script>
