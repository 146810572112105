var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-list-grid"},[(!_vm.hideSort && !_vm.hidePromoFilter)?_c('ProductFilters',{attrs:{"promoFilter":_vm.promoFilter,"newFilter":_vm.newFilter,"sortFilter":_vm.sortFilter,"facets":_vm.facets,"facetsFilter":_vm.facetsFilter,"filteredProductsCount":_vm.filteredProductsCount,"hidePromoFilter":_vm.hidePromoFilter,"hideFilterString":_vm.hideFilterString,"hideSort":_vm.hideSort},on:{"handlePromoFilter":_vm.handlePromoFilter,"handleNewFilter":_vm.handleNewFilter,"handleFacetFilter":_vm.handleFacetFilter,"handleFacetFilterMobile":_vm.handleFacetFilterMobile,"handleSortFilter":_vm.handleSortFilter}}):_vm._e(),(_vm.totItems > 0)?[_c('div',{staticStyle:{"max-height":"100%"}},[_c('v-row',[_vm._l((_vm.products),function(product,idx){return [_c('v-col',{key:product.productId,staticClass:"product-col",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"2"}},[_c('ProductCard',{attrs:{"product":product}})],1),(
              product.productInfos.ENTITY_LINK &&
                product.productInfos.ENTITY_MEDIA
            )?_c('v-col',{key:product.productId + '-entity-relationship-prod',staticClass:"product-col entity-relationship-col entity-relationship-prod",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"2"}},[_c('ProductEntityRelationshipCard',{attrs:{"card":product}})],1):_vm._e(),(
              _vm.entityProductCards.length > 0 &&
                _vm.entityProductCardFromCategory(_vm.entityProductCards, idx)
            )?_c('v-col',{key:product.productId + '-entity-relationship-cat',staticClass:"product-col entity-relationship-col entity-relationship-cat",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"2"}},[_c('product-proposal-card',{attrs:{"proposal":_vm.entityProductCardFromCategory(_vm.entityProductCards, idx)}})],1):_vm._e()]})],2)],1),(_vm.$vuetify.breakpoint.xs && _vm.hasMoreItems)?_c('v-btn',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.onIntersect),expression:"onIntersect"}],attrs:{"block":"","loading":_vm.loading},on:{"click":_vm.loadMore}},[(_vm.hasMoreItems)?[_vm._v(_vm._s(_vm.$t("products.showMore")))]:[_vm._v(_vm._s(_vm.$t("products.showAll")))]],2):_vm._e(),(_vm.pager && _vm.pager.totPages > 1 && !_vm.$vuetify.breakpoint.xs)?_c('v-pagination',{attrs:{"color":"white","circle":"","value":_vm.pageFilter,"page":_vm.pager.selPage,"length":_vm.pager.totPages ? _vm.pager.totPages : 0,"totalVisible":7},on:{"next":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handlePageFilter.apply(null, arguments)},"prev":_vm.handlePageFilter,"input":_vm.handlePageFilter}}):_vm._e()]:(_vm.loading)?_c('div',[_c('v-row',_vm._l((12),function(index){return _c('v-col',{key:index,attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"2"}},[_c('v-skeleton-loader',{staticClass:"product-list-grid-skeleton",attrs:{"type":"image, list-item-three-line"}})],1)}),1)],1):(_vm.promoFilter)?_c('span',[_vm._v("Nessun prodotto in promozione")]):_c('span',[_vm._v("Nessun prodotto trovato")])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }