<template>
  <v-card
    v-if="card"
    outlined
    rounded="md"
    class="product-entity-relationship-card"
    @click.prevent="clicked"
  >
    <v-img
      cover
      :src="$ebsn.meta(card, 'productInfos.ENTITY_MEDIA')"
      alt="prodotto promozionale"
    >
      <v-card-actions class="justify-center align-end h-100">
        <v-btn
          color="secondary"
          outlined
          x-large
          class="px-8 font-weight-bold"
          :to="$ebsn.meta(card, 'productInfos.ENTITY_LINK')"
        >
          {{
            $ebsn.meta(card, "productInfos.ENTITY_BTN_LABEL") ||
              $t("products.entityRelationshipBtn")
          }}
        </v-btn>
      </v-card-actions>
    </v-img>
  </v-card>
</template>
<style lang="scss">
.product-entity-relationship-card {
  .v-image {
    height: $product-card-height;
    // @media #{map-get($display-breakpoints, 'xs-only')} {
    //   height: $product-card-mobile-height;
    // }
  }
}
</style>
<script>
import clickHandler from "~/mixins/clickHandler";

import get from "lodash/get";

export default {
  name: "ProductEntityRelationshipCard",
  mixins: [clickHandler],
  props: {
    card: {
      type: Object,
      required: true
    }
  },
  computed: {
    entityImgSrc() {
      let img = get(this.card, "productInfos.ENTITY_MEDIA", null);
      if (!img) {
        img = get(this.card, "img", "");
      }
      return img;
    },
    entityLink() {
      let link = get(this.card, "productInfos.ENTITY_LINK", null);
      if (!link) {
        link = this.link;
      }
      return link;
    },
    entityBtnLabel() {
      let label = get(this.card, "productInfos.ENTITY_BTN_LABEL", null);
      if (!label) {
        label = get(this.card, "name", "");
      }
      return label;
    }
  }
};
</script>
