<template>
  <div class="product-list-grid">
    <ProductFilters
      v-if="!hideSort && !hidePromoFilter"
      :promoFilter="promoFilter"
      :newFilter="newFilter"
      :sortFilter="sortFilter"
      :facets="facets"
      :facetsFilter="facetsFilter"
      :filteredProductsCount="filteredProductsCount"
      :hidePromoFilter="hidePromoFilter"
      :hideFilterString="hideFilterString"
      :hideSort="hideSort"
      @handlePromoFilter="handlePromoFilter"
      @handleNewFilter="handleNewFilter"
      @handleFacetFilter="handleFacetFilter"
      @handleFacetFilterMobile="handleFacetFilterMobile"
      @handleSortFilter="handleSortFilter"
    />
    <template v-if="totItems > 0">
      <div style="max-height:100%">
        <v-row>
          <template v-for="(product, idx) in products">
            <v-col
              cols="12"
              sm="6"
              md="4"
              lg="3"
              xl="2"
              v-bind:key="product.productId"
              class="product-col"
            >
              <ProductCard v-bind:product="product" />
            </v-col>
            <v-col
              v-if="
                product.productInfos.ENTITY_LINK &&
                  product.productInfos.ENTITY_MEDIA
              "
              cols="12"
              sm="6"
              md="4"
              lg="3"
              xl="2"
              v-bind:key="product.productId + '-entity-relationship-prod'"
              class="product-col entity-relationship-col  entity-relationship-prod"
            >
              <!-- entityReplationship connected to a product -->
              <ProductEntityRelationshipCard :card="product" />
            </v-col>
            <v-col
              v-if="
                entityProductCards.length > 0 &&
                  entityProductCardFromCategory(entityProductCards, idx)
              "
              cols="12"
              sm="6"
              md="4"
              lg="3"
              xl="2"
              v-bind:key="product.productId + '-entity-relationship-cat'"
              class="product-col entity-relationship-col entity-relationship-cat"
            >
              <!-- entityReplationship connected to a category -->
              <product-proposal-card
                :proposal="
                  entityProductCardFromCategory(entityProductCards, idx)
                "
              />
            </v-col>
          </template>
        </v-row>
      </div>
      <v-btn
        block
        v-intersect="onIntersect"
        :loading="loading"
        @click="loadMore"
        v-if="$vuetify.breakpoint.xs && hasMoreItems"
      >
        <template v-if="hasMoreItems">{{ $t("products.showMore") }}</template>
        <template v-else>{{ $t("products.showAll") }}</template>
      </v-btn>
      <!-- v-model="page" -->
      <v-pagination
        color="white"
        circle
        v-if="pager && pager.totPages > 1 && !$vuetify.breakpoint.xs"
        :value="pageFilter"
        :page="pager.selPage"
        :length="pager.totPages ? pager.totPages : 0"
        :totalVisible="7"
        @next.stop.prevent="handlePageFilter"
        @prev="handlePageFilter"
        @input="handlePageFilter"
      ></v-pagination>

      <!-- @next="fetchProducts"
        @prev="fetchProducts"
        @input="fetchProducts" -->
    </template>
    <div v-else-if="loading">
      <v-row>
        <v-col
          v-for="index in 12"
          :key="index"
          cols="12"
          sm="6"
          md="4"
          lg="3"
          xl="2"
        >
          <v-skeleton-loader
            class="product-list-grid-skeleton"
            type="image, list-item-three-line"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <span v-else-if="promoFilter">Nessun prodotto in promozione</span>
    <span v-else>Nessun prodotto trovato</span>
  </div>
</template>
<script>
import ProductService from "~/service/productService";
import ProductCard from "@/components/product/ProductCard.vue";
import ProductEntityRelationshipCard from "@/components/product/ProductEntityRelationshipCard.vue";
import ProductFilters from "@/components/product/filters/ProductFilters.vue";
import { mapGetters } from "vuex";
import ProductProposalCard from "./ProductProposalCard.vue";
import analyticsService from "~/service/analyticsService";

export default {
  name: "ProductList",
  props: {
    parentCategoryId: { type: Number, required: false },
    categoryId: { type: Number, required: false },
    query: { type: String, required: false },
    barcode: { type: String, required: false },
    mode: { type: String, required: false },
    hidePromoFilter: { type: Boolean, default: false },
    hideFilterString: { type: String, required: false },
    hideSort: { type: Boolean, default: false },
    entityProductCards: { type: Array, default: () => [] }
  },
  components: {
    ProductCard,
    ProductEntityRelationshipCard,
    ProductFilters,
    ProductProposalCard
  },
  jsonld() {
    if (this.products) {
      var items = this.products.map((item, index) => ({
        "@type": "ListItem",
        position: index + 1,
        url: process.env.VUE_APP_EBSN_URL + "/" + item.slug
      }));
      return {
        "@context": "https://schema.org",
        "@type": "ItemList",
        itemListElement: items
      };
    }
  },
  data() {
    return {
      products: [],
      facets: [],
      // selectedFacets: {},
      // selectedSort: null,
      pager: {},
      page: 1,
      busy: false,
      loading: false,
      filteredProductsCount: 0
      // promo: false,
      // selectedFacetsXS: [],
    };
  },
  computed: {
    queryLabel() {
      return this.barcode ? this.barcode : this.query;
    },
    currentRouteName() {
      return this.$route.name;
    },
    totItems() {
      return this.pager && this.pager.totItems ? this.pager.totItems : 0;
    },
    ...mapGetters({
      promoFilter: "category/promoFilter",
      newFilter: "category/newFilter",
      sortFilter: "category/sortFilter",
      pageFilter: "category/pageFilter"
    }),

    facetsFilter: {
      get() {
        return this.$store.state.category.filters.facets;
      },
      set() {
        // this.$store.commit("app/updatedDrawerLeft", value);
      }
    },

    hasMoreItems() {
      if (this.pager) {
        return this.pager.totPages > this.pager.selPage;
      } else {
        return false;
      }
    }
  },
  methods: {
    entityProductCardFromCategory(productCards, idx) {
      let idxEveryPage = idx % this.pager.itemsPerPage;
      let entityProductCard = productCards.find(
        item => item.priority === idxEveryPage
      );
      return entityProductCard ? entityProductCard : null;
    },
    handleSortFilter(sort) {
      this.$store.dispatch("category/setFilterSort", sort);
      this.fetchProducts();
    },
    handlePromoFilter(promo) {
      this.$store.dispatch("category/setFilterPromo", promo);
      this.fetchProducts();
    },
    handleNewFilter(onlyNew) {
      this.$store.dispatch("category/setFilterNew", onlyNew);
      this.fetchProducts();
    },
    handleFacetFilter(filter) {
      this.$store.dispatch("category/setFilterFacets", {
        facets: filter.facets,
        parameterName: filter.parameterName
      });
      this.fetchProducts();
    },
    handleFacetFilterMobile(selectedFacetsXS) {
      this.$store.dispatch("category/setFilterFacetsMobile", {
        facets: selectedFacetsXS
      });
      this.fetchProducts();
    },
    handlePageFilter(page) {
      var _this = this;
      // console.log(page);
      this.$store.dispatch("category/setFilterPage", page);
      //await di this.fetchProduts
      this.fetchProducts();
      //oppure mettere goTo(0) dentro timeout
      setTimeout(function() {
        _this.$vuetify.goTo(0);
      }, 300);
    },

    onIntersect(entries) {
      if (entries[0].isIntersecting) {
        this.loadMore();
      }
    },
    loadMore() {
      if (this.hasMoreItems) {
        this.$store.dispatch("category/setFilterPage", this.pageFilter + 1);
        this.fetchProducts(true);
      }
    },
    async fetchProducts(append) {
      this.loading = true;
      let response = await ProductService.search(
        {
          parent_category_id: this.parentCategoryId,
          category_id: this.categoryId,
          q: this.query,
          barcode: this.barcode,
          page: this.pageFilter,
          page_size: process.env.VUE_APP_PRODUCT_LIST_PAGE_SIZE,
          sort: this.sortFilter,
          promo: this.promoFilter,
          new_product: this.newFilter
        },
        this.facetsFilter
      );

      // // TEST entityRelationship
      // response.products[1].media_attachments = {
      //   other_11: {
      //     raw:
      //       "https://www.latuaspesa.com/photo/2022/05/23/0/p_card_entity/raw/zueggpcardentity.png"
      //   }
      // };
      // response.products[1].entityRelationship = {
      //   1: [
      //     {
      //       id: 9,
      //       slug:
      //         "colazione-e-merenda/marmellate-miele-e-spalmabili/marmellate-e-confetture",
      //       priority: 0,
      //       name: "MARMELLATE E CONFETTURE",
      //       descr: "MARMELLATE E CONFETTURE",
      //       content: "",
      //       img: "",
      //       entityId: 42946,
      //       proposalUrl: ""
      //     }
      //   ]
      // };
      // // fine test

      // Se ho letto il barcode e mi torna un Prodotto, navigo diretto al Dettaglio
      if (this.barcode && response.products.length > 0) {
        this.$router.push({
          name: "Product",
          params: { slug: response.products[0].slug }
        });
      } else if (append) {
        this.products = [...this.products, ...response.products];
      } else {
        this.products = response.products;
      }

      if (response.products.length > 0) {
        analyticsService.viewProducts(
          response.products,
          this.position,
          (response.page.selPage - 1) * response.page.itemsPerPage
        );
      }

      this.pager = response.page;
      this.facets = response.facets;
      this.$emit("productsCount", this.pager.totItems);
      this.filteredProductsCount = response.page.totItems;
      if (this.pager.searchedText) {
        this.$emit("searchedText", this.pager.searchedText);
      }
      this.loading = false;
      this.loading = false;
    },
    selectFilter(e) {
      console.log(e);
    }
  },
  async created() {
    if (this.$vuetify.breakpoint.xsOnly) {
      let page = this.pageFilter;
      for (let i = 1; i <= page; i++) {
        this.$store.dispatch("category/setFilterPage", i);
        await this.fetchProducts(true);
      }
    } else {
      this.fetchProducts();
    }
  }
};
</script>
