<template>
  <div class="product-sort-filter d-flex align-center w-100">
    <span class="d-none d-lg-block">{{ $t("filter.orderBy") }}</span>
    <v-select
      :items="sortOptions"
      :value="sortFilter"
      color="accent"
      item-color="accent"
      @change="handleSortFilter"
      hide-details
      flat
      single-line
      clearable
      solo
      dense
      :label="$t('filter.orderBy')"
      :class="
        $vuetify.breakpoint.xs ? 'product-sort-filters-mobile' : 'ps-4 pt-0'
      "
      height="36"
      ><template v-slot:prepend-inner
        ><v-icon color="accent" class="d-flex d-sm-none mx-1"
          >$sort</v-icon
        ></template
      >
      <template v-slot:append
        ><v-icon color="accent" class="d-none d-sm-flex"
          >$expand</v-icon
        ></template
      ></v-select
    >
  </div>
</template>

<script>
export default {
  name: "ProductSortFilter",
  data() {
    return {
      sortOptions: [
        { value: "", text: "Rilevanza", icon: "ion-arrow-up-c" },
        {
          value: "promo",
          text: "In promozione",
          icon: "ion-arrow-up-c"
        },
        {
          value: "new_product",
          text: "Novità",
          icon: "ion-arrow-up-c"
        },
        { value: "pri_asc", text: "Prezzo crescente", icon: "ion-arrow-up-c" },
        {
          value: "pri_des",
          text: "Prezzo decrescente",
          icon: "ion-arrow-down-c"
        },
        {
          value: "pri_um_asc",
          text: "Prezzo al kg crescente",
          icon: "ion-arrow-down-c"
        },
        {
          value: "pri_um_des",
          text: "Prezzo al kg descrescente",
          icon: "ion-arrow-down-c"
        },
        {
          value: "alf_asc",
          text: "Alfabetico crescente",
          icon: "ion-arrow-up-c"
        }
      ]
    };
  },
  props: { sortFilter: { type: Object } },
  methods: {
    handleSortFilter(sort) {
      this.$emit("handleSortFilter", sort);
    }
  }
};
</script>

<style scoped lang="scss">
@media #{map-get($display-breakpoints, 'xs-only')} {
  .v-input__control {
    min-height: auto !important;
  }
}
</style>
