<template>
  <div class="d-flex product-facet-filter align-center">
    <div
      class="accent--text mr-2 d-none d-lg-block"
      style="white-space: nowrap"
      v-if="facetsFiltered && facetsFiltered.length > 0"
    >
      <!-- d-none d-lg-block -->
      <v-icon color="accent">$filter</v-icon>
      {{ $t("filter.filterBy") }}
    </div>
    <v-tabs
      show-arrows
      next-icon="$next"
      prev-icon="$prev"
      background-color="accent lighten-1"
    >
      <v-tabs-slider color="accent lighten-1"></v-tabs-slider>
      <v-tab v-for="facet in facetsFiltered" :key="facet.name" class="px-2">
        <v-select
          :items="facet.values"
          :item-text="facetLabel"
          :label="facet.name"
          :item-disabled="facetDisabled"
          append-icon="$expand"
          class="facet-filter d-flex"
          background-color="#fff"
          color="accent"
          item-color="accent"
          multiple
          solo
          clearable
          flat
          dense
          chips
          single-line
          hide-details
          deletable-chips
          item-value="id"
          height="36"
          @change="handleFacetFilter($event, facet.parameterName)"
          :value="facetsFilter[facet.parameterName]"
        >
          <template v-slot:selection="{ index }">
            <!-- <v-chip
          v-if="facetsFilter[facet.parameterName].length == 1 && index === 0"
        >
          <span>{{ item.name }}</span>
        </v-chip> -->
            <span
              class="grey--text caption"
              v-if="facetsFilter[facet.parameterName].length > 0 && index === 0"
            >
              (+{{ facetsFilter[facet.parameterName].length }} filtri)
            </span>
          </template>

          <!-- <template v-slot:item="data">
        {{ data.item.name }}
      </template> -->
        </v-select>
      </v-tab>
    </v-tabs>
  </div>
</template>
<style global lang="scss">
.facet-filter {
  .v-select__selections {
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: hidden;
    .v-chip {
      overflow: initial;
    }
  }
  .v-select__slot {
    .v-label {
      text-transform: none;
      color: $text-color;
    }
  }
}
.v-tabs {
  .v-input__control {
    height: 36px;
  }
  .v-text-field .v-input__slot {
    max-width: 160px;
  }
}
.product-filters {
  .v-text-field .v-input__slot {
    border-radius: 0px !important;
  }
  .v-select__selection--comma {
    color: $text-color !important;
    font-size: 14px;
  }
}
</style>
<script>
export default {
  name: "ProductFacetFilter",
  //   data() {
  //       return {
  //           selectedFacetsXS : []
  //       }
  //   },
  props: {
    facets: { type: Array },
    facetsFilter: { type: Object },
    hideFilterString: { type: String, default: "" }
  },
  computed: {
    facetFilter: item => {
      return item.filteredCount > 0;
    },
    facetsFiltered() {
      let vm = this;
      if (vm.facets) {
        return vm.facets.filter(
          item => vm.hideFilterString.indexOf(item.name) < 0
        );
      }
      return [];
    }
  },
  methods: {
    handleFacetFilter(facets, parameterName) {
      this.$emit("handleFacetFilter", { facets, parameterName });
    },
    facetLabel(item) {
      return item.name + " (" + item.filteredCount + ")";
    },
    facetDisabled: facet => facet.filteredCount == 0
  }
};
</script>

<style></style>
